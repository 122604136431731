<template>
  <ValidationProvider
    tag="label"
    :rules="rules"
    :name="name || label"
    v-slot="{ errors, required }"
    class="textarea"
  >
    <textarea
      :value="value"
      :placeholder="placeholder"
      @input="$emit('input', $event.target.value)"
      @blur="$emit('blur', $event)"
      class="field"
      :class="{ 'field--invalid': errors.length }"
    ></textarea>

    <span
      v-if="label"
      :for="id"
      class="label"
      :class="{ 'label--invalid': errors.length }"
    >
      <span>{{ label || name }}</span>
      <span>{{ required ? " *" : "" }}</span>
    </span>

    <span class="error">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "CustomTextarea",

  components: { ValidationProvider },

  props: {
    value: {
      default: "",
      type: String
    },

    rules: {
      default: "",
      type: [String, Object]
    },

    name: {
      type: String,
      default: ""
    },

    label: String,
    placeholder: String,
    id: String
  }
};
</script>

<style scoped>
.textarea {
  position: relative;
  display: block;
}

.label {
  position: absolute;
  top: calc(0.75rem / -2);
  left: var(--lengthMd1);
  padding: 2px 4px;
  background-color: white;
  font-size: 0.75rem;
  line-height: 100%;
}

.label--invalid,
.error {
  color: var(--dangerColor);
}

.error {
  margin-top: var(--lengthSm1);
  font-size: 0.75rem;
}

.field:not(.field--invalid):focus ~ .label {
  color: var(--mainColor1);
}

.field {
  min-height: 100px;
  width: 100%;
  padding: var(--lengthSm3) var(--lengthSm2) var(--lengthSm2);
  color: var(--fontColor2);
  font-weight: var(--medium);
  background-color: white;
  box-shadow: 0 0 0 1px var(--grayColor2);
  border-radius: var(--lengthSm1);
  transition: box-shadow 300ms;
}

.field:not(.field--invalid):focus {
  box-shadow: 0 0 0 2px var(--mainColor1);
}

.field--invalid {
  border-color: var(--dangerColor);
}
</style>
