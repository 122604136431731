<template>
  <router-link :to="to" class="back-btn">
    <font-awesome-icon :icon="icons.back" />
    {{ label }}
  </router-link>
</template>

<script>
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

export default {
  name: "BackButton",

  props: {
    to: {
      required: true,
      type: String
    },

    label: {
      default: "Volver",
      type: String
    }
  },

  data: () => ({
    icons: {
      back: faChevronLeft
    }
  })
};
</script>

<style scoped>
.back-btn {
  display: flex;
  align-items: center;
  grid-gap: var(--lengthSm1);
  gap: var(--lengthSm1);
  color: var(--fontColor3);
  font-weight: var(--medium);
  transition: color 300ms;
}

.back-btn:hover {
  color: var(--fontColor2);
}
</style>
