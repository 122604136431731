<template>
  <ValidationProvider
    tag="fieldset"
    :rules="rules"
    :name="name || label"
    v-slot="{ errors }"
  >
    <fieldset
      class="scale"
      :class="{ 'scale--sm': size === 'sm', 'scale--invalid': errors.length }"
    >
      <legend class="scale__label">
        {{ label }}
      </legend>

      <div class="scale__scale">
        <ENPSScaleItem
          v-for="n in 11"
          :key="n"
          :label="n - 1"
          :picked="picked"
          :size="size"
          :value="n - 1"
          @change="$emit('change', $event)"
        />
      </div>

      <span v-if="errors.length" class="error">
        {{ errors[0] }}
      </span>
    </fieldset>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

import ENPSScaleItem from "./ENPSScaleItem.vue";

export default {
  name: "Scale",

  components: { ValidationProvider, ENPSScaleItem },

  model: {
    event: "change",
    prop: "picked"
  },

  props: {
    label: {
      required: true,
      type: String
    },

    picked: {
      required: true,
      type: Number
    },

    rules: {
      default: "",
      type: [String, Object]
    },

    name: {
      default: "",
      type: String
    },

    size: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped>
.scale__label {
  width: fit-content;
  margin: 0 auto var(--lengthLg2);
  color: var(--fontColor1);
  font-size: var(--lengthMd3);
  font-weight: var(--medium);
  text-align: center;
  background-color: white;
}

.scale__scale {
  width: fit-content;
  display: flex;
  margin: 0 auto;
  border: 1px solid var(--grayColor2);
  border-radius: var(--lengthSm1);
}

.scale--sm .scale__label {
  width: 100%;
  margin: 0 0 var(--lengthSm3);
  font-size: var(--lengthMd1);
  text-align: start;
}

.scale--sm .scale__scale {
  margin: 0;
}

.scale--invalid .scale__label {
  color: var(--dangerColor);
}

.scale--invalid .scale__scale {
  border-color: var(--dangerColor);
}

legend {
  display: table;
  padding: 0;
}

fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0 0 0 0;
}

body:not(:-moz-handler-blocked) fieldset {
  display: table-cell;
}
</style>
