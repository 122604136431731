<template>
  <label
    class="radio"
    :class="{ 'radio--checked': isChecked, 'radio--sm': size === 'sm' }"
  >
    <input
      type="radio"
      name="enps-scale"
      :value="value"
      :checked="isChecked"
      class="radio__input"
      @change="onChange"
    />

    <span class="radio__label">{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: "ENPSScaleItem",

  model: {
    event: "change",
    prop: "picked"
  },

  props: {
    label: {
      required: true,
      type: Number
    },

    picked: {
      required: true,
      type: Number
    },

    size: {
      type: String,
      default: ""
    },

    value: {
      required: true,
      type: Number
    }
  },

  computed: {
    isChecked() {
      return this.value === this.picked;
    }
  },

  methods: {
    onChange(e) {
      this.$emit("change", parseInt(e.target.value, 10));
    }
  }
};
</script>

<style scoped>
.radio {
  padding: var(--lengthSm3) var(--lengthMd3);
  background-color: var(--grayColor1);
  cursor: pointer;
}

.radio + .radio {
  border-left: 1px solid var(--grayColor2);
}

.radio:first-child {
  border-top-left-radius: var(--lengthSm1);
  border-bottom-left-radius: var(--lengthSm1);
}

.radio:last-child {
  border-right-width: 1px;
  border-top-right-radius: var(--lengthSm1);
  border-bottom-right-radius: var(--lengthSm1);
}

.radio__label {
  color: var(--fontColor2);
  font-size: var(--lengthMd2);
  font-weight: var(--medium);
}

.radio__input {
  display: none;
}

.radio--checked {
  background-color: var(--mainColor1);
  border-color: var(--mainColor1);
}

.radio--checked .radio__label {
  color: white;
}

.radio--sm {
  padding: var(--lengthSm3);
}

.radio--sm .radio__label {
  font-size: var(--lengthSm3);
}
</style>
